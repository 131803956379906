import store from '@/store'

import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions, formatStatusOptions, formatCountryOptions } from '@core/utils'

export default function useProject() {

    const vm = getCurrentInstance().proxy

    
    const officeLoading = ref(false)
    const officeOptions = ref([])
    const officeSearch = ref('')

    const categoryLoading = ref(false)
    const categoryOptions = ref([])
    const categorySearch = ref('')

    const busInitLoading = ref(false)
    const busInitOptions = ref([])
    const busInitSearch = ref('')

    const countryLoading = ref(false)
    const countryOptions = ref([])
    const countrySearch = ref('')

    const moduleLoading = ref(false)
    const moduleOptions = ref([])
    const moduleSearch = ref('')

    const projTypeLoading = ref(false)
    const projTypeOptions = ref([])
    const projTypeSearch = ref('')

    const researchLocLoading = ref(false)
    const researchLocOptions = ref([])
    const researchLocSearch = ref('')

    const researchMetLoading = ref(false)
    const researchMetOptions = ref([])
    const researchMetSearch = ref('')

    const servTypeLoading = ref(false)
    const servTypeOptions = ref([])
    const servTypeSearch = ref('')

    const billingRuleLoading = ref(false)
    const billingRuleOptions = ref([])
    const billingRuleSearch = ref('')

    const currencyLoading = ref(false)
    const currencyOptions = ref([])
    const currencySearch = ref('')

    const clientLoading = ref(false)
    const clientOptions = ref([])
    const clientSearch = ref('')

    const userLoading = ref(false)
    const userOptions = ref([])
    const userSearch = ref('')

    const shelfTypeLoading = ref(false)
    const shelfTypeOptions = ref([])
    const shelfTypeSearch = ref('')

    const studioRateCardLoading = ref(false)
    const studioRateCardOptions = ref([])
    const studioRateCardSearch = ref('')

    const qualSampleLoading = ref(false)
    const qualSampleOptions = ref([])
    const qualSampleSearch = ref('')

    const deptLoading = ref(false)
    const deptOptions = ref([])
    const deptSearch = ref('')

    const groupLoading = ref(false)
    const groupOptions = ref([])
    const groupSearch = ref('')

    const statusLoading = ref(false)
    const statusOptions = ref([])
    const statusSearch = ref('')

    const fieldLocationLoading = ref(false)
    const fieldLocationOptions = ref([])
    const fieldLocationSearch = ref('')

    const workTypesLoading = ref(false)
    const workTypesOptions = ref([])
    const workTypesSearch = ref('')

    const intercompanies = ref([])
    const intercompaniesLoading = ref(false)

    const exportLoading = ref(false)

    const swof = ref([])
    const swofLoading = ref(false)
    const swofImage = ref([])
    const swofImageLoading = ref(false)
    const swofNotificationLoading = ref(false)

    const billingStatusOptions = ref([
        {label: 'New Inv to Prep', color: 'warning', value:'PREP'},
        {label: 'Billed', color: 'info', value:'BILL'},
        {label: 'Pay. Received', color: 'success', value:'REC'},
    ])
    
    const billingStatusColor = ref({ 
        PREP: {
            color: 'warning',
            label: 'New Inv to Prep'
        }, 
        BILL: {
            color: 'info',
            label: 'Billed'
        }, 
        REC: {
            color: 'success',
            label: 'Pay. Received'
        }
    })

    const swofQuestionList = ref([
        {"label":"E_Flow_Ex_Shelf1", "type":"shelf"},
        {"label":"E_Flow_Ex_Practice", "type":"shelf"},
        {"label":"P_QuantityPurchased", "type":"shelf"},
        {"label":"P_CategoryDriver", "type":"shelf"},
        {"label":"Q_ShelfPrimer", "type":"shelf"},
        {"label":"F_Flash_Practice", "type":"sku"},
        {"label":"F_Flash1", "type":"sku"},
        {"label":"E_Flow_Ex_Pack_Practice", "type":"sku"},
        {"label":"E_Flow_Ex_Pack1", "type":"sku"},
        {"label":"F_Flash_Recall", "type":"sku"},
        {"label":"Q_DESIGNAPPEAL", "type":"sku"},
        {"label":"Q_ClickonLike", "type":"sku"},
        {"label":"Q_ClickonDislike", "type":"sku"},
        {"label":"Q_PackAtt", "type":"sku"},
        {"label":"Q_Fit_brand", "type":"sku"},
        {"label":"Q_Communication2", "type":"sku"},
        {"label":"Q_PropensityToBuy", "type":"sku"},
        {"label":"Q_COMMUNICATION", "type":"sku"},
        {"label":"Q_OA_5pts", "type":"sku"},
        {"label":"Q_OA_4pts", "type":"sku"},
        {"label":"Q_InitialImpressions", "type":"sku"},
        {"label":"Q_DESIGNAPPEAL", "type":"sku"},
        {"label":"Q_DESIGNAPPEAL_6", "type":"sku"},
        {"label":"Q_OverallLiking", "type":"sku"},
        {"label":"Q_PropensityToBuy_Unpriced", "type":"sku"},
        {"label":"Q_Price_Perception", "type":"sku"},
        {"label":"Q_Value", "type":"sku"},
        {"label":"Q_PI", "type":"sku"},
        {"label":"Q_PI_variants", "type":"sku"},
        {"label":"Q_Benefit", "type":"sku"},
        {"label":"F_Emotion", "type":"sku"},
        {"label":"PSM_MIN", "type":"sku"},
        {"label":"F_IAT", "type":"sku"},
        {"label":"F_Flash1_COMP", "type":"sku"},
        {"label":"F_Flash_Recall", "type":"line"},
        {"label":"Q_DESIGNAPPEAL", "type":"line"},
        {"label":"Q_PackAtt", "type":"line"},
        {"label":"Q_Fit_brand", "type":"line"},
        {"label":"Q_Communication2", "type":"line"},
        {"label":"Q_PropensityToBuy", "type":"line"},
        {"label":"Q_COMMUNICATION", "type":"line"},
        {"label":"Q_OA_5pts", "type":"line"},
        {"label":"Q_OA_4pts", "type":"line"},
        {"label":"Q_InitialImpressions", "type":"line"},
        {"label":"Q_OA_Variant", "type":"line"},
        {"label":"Q_DESIGNAPPEAL", "type":"line"},
        {"label":"Q_DESIGNAPPEAL_6", "type":"line"},
        {"label":"Q_OverallLiking", "type":"line"},
        {"label":"Q_PropensityToBuy_Unpriced", "type":"line"},
        {"label":"Q_Price_Perception", "type":"line"},
        {"label":"Q_Value", "type":"line"},
        {"label":"Q_PI", "type":"line"},
        {"label":"Q_Benefit", "type":"line"},
        {"label":"F_Emotion", "type":"line"},
        {"label":"PSM_MIN", "type":"line"},
        {"label":"VF_Allocation", "type":"line"},
        {"label":"R_AidedRecall", "type":"aided_recall"}
    ])
    

    const fetchUsers = async () => {
        userLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchUsers', {
        })
        .then(response => {
            const { data } = response
            userOptions.value = data
            // remove loading state
            userLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchOffices = async () => {
        officeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchOffices', {})
        .then(response => {
            const { data } = response
            officeOptions.value = formatOptions(data)

            // remove loading state
            officeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchCategories = async () => {
        categoryLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchCategories', {})
        .then(response => {
            const { data } = response
            categoryOptions.value = formatOptions(data)

            // remove loading state
            categoryLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchBusinessInit = async () => {
        busInitLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchBusinessInit', {})
        .then(response => {
            const { data } = response
            busInitOptions.value = formatOptions(data)

            // remove loading state
            busInitLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchCountries = async () => {
        countryLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchCountries', {})
        .then(response => {
            const { data } = response
            countryOptions.value = formatCountryOptions(data)

            // remove loading state
            countryLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchModules = async () => {
        moduleLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchModules', {})
        .then(response => {
            const { data } = response
            moduleOptions.value = formatOptions(data)

            // remove loading state
            moduleLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchProjectTypes = async () => {
        projTypeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchProjectTypes', {})
        .then(response => {
            const { data } = response
            projTypeOptions.value = formatOptions(data)

            // remove loading state
            projTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchResearchLocations = async () => {
        researchLocLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchResearchLocations', {})
        .then(response => {
            const { data } = response
            researchLocOptions.value = formatOptions(data)

            // remove loading state
            researchLocLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchResearchMethods = async () => {
        researchMetLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchResearchMethods', {})
        .then(response => {
            const { data } = response
            researchMetOptions.value = formatOptions(data)

            // remove loading state
            researchMetLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchServiceTypes = async () => {
        servTypeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchServiceTypes', {})
        .then(response => {
            const { data } = response
            servTypeOptions.value = formatOptions(data)

            // remove loading state
            servTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchBillingRules = async () => {
        billingRuleLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchBillingRules', {})
        .then(response => {
            const { data } = response
            billingRuleOptions.value = formatOptions(data)

            // remove loading state
            billingRuleLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    const fetchCurrencies = async () => {
        currencyLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchCurrencies', {})
        .then(response => {
            const { data } = response
            currencyOptions.value = formatOptions(data)

            // remove loading state
            currencyLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchClients = async () => {
        clientLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchClients', {})
        .then(response => {
            const { data } = response
            clientOptions.value = formatOptions(data)

            // remove loading state
            clientLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchShelfTypes = async () => {
        shelfTypeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchShelfTypes', {})
        .then(response => {
            const { data } = response
            shelfTypeOptions.value = formatOptions(data)

            // remove loading state
            shelfTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchStudioRateCards = async () => {
        studioRateCardLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchStudioRateCards', {})
        .then(response => {
            const { data } = response
            studioRateCardOptions.value = formatOptions(data)

            // remove loading state
            studioRateCardLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchQualSampleType = async () => {
        qualSampleLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchQualSampleType', {})
        .then(response => {
            const { data } = response
            qualSampleOptions.value = formatOptions(data)

            // remove loading state
            qualSampleLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchDepartments = async () => {
        deptLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-project/fetchDepartments', {
            in_project: 1
          })
          .then(response => {
            const { data } = response
            deptOptions.value = formatOptions(data)
    
            // remove loading state
            deptLoading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    
    const fetchGroups = async () => {
        groupLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-project/fetchGroups', {
          })
          .then(response => {
            const { data } = response
            groupOptions.value = data
    
            // remove loading state
            groupLoading.value = false
          })
          .catch(error => {
            console.log(error)
          })
    }

    const fetchStatuses = async () => {
        statusLoading.value = true
        
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
            .dispatch('app-project/fetchStatuses', {
            })
            .then(response => {
            const { data } = response
            statusOptions.value = formatStatusOptions(data)
    
            // remove loading state
            statusLoading.value = false
            
            })
            .catch(error => {
            console.log(error)
            })
    }

    const fetchFieldLocations = async () => {
        fieldLocationLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
            .dispatch('app-project/fetchFieldLocations', {
            })
            .then(response => {
            const { data } = response
            fieldLocationOptions.value = data
            
            // remove loading state
            fieldLocationLoading.value = false
            
            })
            .catch(error => {
            console.log(error)
            })
    }
    const downloadTimeline = async (project) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        exportLoading.value = true
        store
        .dispatch('app-project/downloadTimeline', 
          project
        )
        .then(response => {
            const { data } = response
            exportLoading.value = false
            const disposition = response.headers['content-disposition'];
            const filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            
            let fileURL = window.URL.createObjectURL(new Blob([data]));
            let fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
        
            fileLink.click();

        })
        .catch(error => {
            exportLoading.value = false
          // store.commit('app/LOADING', false)
          console.log(error.response)
          store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
        })
    }

    const questionnaireLoading = ref(false)
    const questionnaire = ref({})
    const fetchQuestionnaire = async (id, queryParams) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        questionnaireLoading.value = true
        await store
        .dispatch('app-project/fetchQuestionnaire', id, queryParams)
        .then(response => {
            const { data } = response
  
            questionnaire.value = data
            questionnaireLoading.value = false
  
        })
        .catch(error => {
            console.log(error)
            router.push({
            name: 'misc-error'
            })
        })
      }

    // const fetchIntercoWorkTypes = async () => {
    //     workTypesLoading.value = true
    //     const token = await vm.$msal.getTokenPopup(foundryRequest)
    //     store
    //         .dispatch('app-project/fetchIntercoWorkTypes', {
    //         })
    //         .then(response => {
    //         const { data } = response
    //         workTypesOptions.value = formatOptions(data)
            
    //         // remove loading state
    //         workTypesLoading.value = false
            
    //         })
    //         .catch(error => {
    //         console.log(error)
    //         })
    // }

    // const fetchIntercos = async (project) => {
    //     intercompaniesLoading.value = true
    //     const token = await vm.$msal.getTokenPopup(foundryRequest)
    //     store
    //         .dispatch('app-project/fetchIntercos', {
    //             project
    //         })
    //         .then(response => {
    //         const { data } = response
    //         intercompanies.value = data
            
    //         // remove loading state
    //         intercompaniesLoading.value = false
            
    //         })
    //         .catch(error => {
    //         console.log(error)
    //         })
    // }

    // const addInterco = async (data) => {
    //     intercompaniesLoading.value = true
    //     const token = await vm.$msal.getTokenPopup(foundryRequest)
    //     store
    //         .dispatch('app-project/addInterco', data)
    //         .then(response => {
    //         const { data } = response

    //         intercompanies.value.push(data)
    //         // remove loading state
    //         intercompaniesLoading.value = false
            
    //         })
    //         .catch(error => {
    //         console.log(error)
    //         })
    // }

    // const updateInterco = async (data) => {
    //     intercompaniesLoading.value = true
    //     const token = await vm.$msal.getTokenPopup(foundryRequest)
    //     store
    //         .dispatch('app-project/updateInterco', data)
    //         .then(response => {
    //         const { data } = response
    //         const index = intercompanies.value.findIndex(interco => {
    //             return interco.id === data.id
    //         })
    //         intercompanies.value.splice(index, 1, data)

    //         // intercompanies.value.map(interco => {
    //         //     if(interco.id == data.id) interco.amount = data.amount
    //         // })
    //         // remove loading state
    //         intercompaniesLoading.value = false
            
    //         })
    //         .catch(error => {
    //         console.log(error)
    //         })
    // }

    // const deleteInterco = async (data) => {
    //     intercompaniesLoading.value = true
    //     const token = await vm.$msal.getTokenPopup(foundryRequest)
    //     store
    //         .dispatch('app-project/deleteInterco', {
    //             ...data
    //         })
    //         .then(response => {
    //             const index = intercompanies.value.findIndex(interco => {
    //                 return interco.id === data.id
    //             })
    //             intercompanies.value.splice(index, 1)
                
    //             // remove loading state
    //             intercompaniesLoading.value = false
            
    //         })
    //         .catch(error => {
    //         console.log(error)
    //         })
    // }

    const swofShelves = [
        {
            "label": "SHELF1A",
            "description": "SHELF 1 Design 1",
            "questions": ['Web Shopping'],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": true,
            "url": "https://bvagroup.sharepoint.com/sites/PRSINVIVOProjectsCompleted/Shared%20Documents/MONDELEZ/2023/GC411%20-%20Milka%20MAM/2.%20Graphics/4.%20Programming%20Images%20(Studio%20Created)/1.%20Shelves/SHELF1_HR.jpg"
        },
        {
            "label": "SHELF1B",
            "description": "SHELF 1 Design 2",
            "questions": ['Web Shopping'],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": "https://bvagroup.sharepoint.com/sites/PRSINVIVOProjectsCompleted/Shared%20Documents/SC%20JOHNSON/2023/GC662%20-%20SCJ%20Raid%20VBL%20R3%20PC360/2.%20Graphics/4.%20Programming%20Images%20(Studio%20Created)/1.%20Shelves/SHELF1A_HR.jpg"
        },
        {
            "label": "SHELF1C",
            "description": "SHELF 1 Design 3",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        },
        {
            "label": "SHELF1D",
            "description": "SHELF 1 Design 4",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        },
        {
            "label": "SHELF1E",
            "description": "SHELF 1 Design 5",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        },
        {
            "label": "SHELF1F",
            "description": "SHELF 1 Design 6",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        },
        {
            "label": "SHELF1G",
            "description": "SHELF 1 Design 7",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        },
        {
            "label": "SHELF1H",
            "description": "SHELF 1 Design 8",
            "questions": [],
            "lifesizePercent": 100,
            "jpg": true,
            "prints": 0,
            "price": true,
            "notes": "",
            "approved": false,
            "url": ""
        }
    ]

    
    const fetchSWOF = async (queryParams) => {
        swofLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/fetchSWOF', queryParams)
            .then(response => {
            const { data } = response
            swof.value = data.results
            
            // remove loading state
            swofLoading.value = false
            
            })
            .catch(error => {
                swofLoading.value = false
            console.log(error)
            })
    }
    const addSWOF = async (payload) => {
        swofLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/addSWOF', payload)
            .then(response => {
            const { data } = response
            swof.value = data
            
            // remove loading state
            swofLoading.value = false
            
            })
            .catch(error => {
                swofLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
            })
    }
    const updateSWOF = async (payload) => {
        swofLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/updateSWOF', payload)
            .then(response => {
            const { data } = response
            swof.value = data
            
            // remove loading state
            swofLoading.value = false
            
            })
            .catch(error => {
                swofLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
            })
    }
    const updateSWOFURL = async (payload) => {
        swofLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/updateSWOFURL', payload)
            .then(response => {
            const { data } = response
            swof.value = data
            
            // remove loading state
            swofLoading.value = false
            
            })
            .catch(error => {
                swofLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
            })
    }
    const addSWOFImage = async (payload) => {
        
        swofImageLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/addSWOFImage', payload)
            .then(response => {
            const { data } = response
            swofImage.value = data
            
            // remove loading state
            swofImageLoading.value = false
            
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                swofImageLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }
    const updateSWOFImage = async (payload) => {
        swofImageLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/updateSWOFImage', payload)
            .then(response => {
            const { data } = response
            swofImage.value = data
            
            // remove loading state
            swofImageLoading.value = false
            
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                swofImageLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }
    
    const deleteSWOFImage = async (payload) => {
        swofImageLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
            .dispatch('app-project/deleteSWOFImage', payload)
            .then(response => {
            const { data } = response
            swofImage.value = data
            
            // remove loading state
            swofImageLoading.value = false
            
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                swofImageLoading.value = false
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }

    const sendSWOFNotification = async (payload) => {
        swofNotificationLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
        .dispatch('app-project/sendNotificationProject', payload)
        .then(response => {
            const { data } = response
            store.commit('comp-alert/SET_MESSAGE', {})
            swofNotificationLoading.value = false
        })
        .catch(error => {
            swofNotificationLoading.value = false
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

    const addFavoriteProject = async (payload) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
        .dispatch('app-project/addFavoriteProject', payload)
        .then(response => {
        const { data } = response
            store.commit('comp-alert/SET_MESSAGE', {})
        })
        .catch(error => {
            swofImageLoading.value = false
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

    const deleteFavoriteProject = async (payload) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        await store
        .dispatch('app-project/deleteFavoriteProject', payload)
        .then(response => {
        const { data } = response
            store.commit('comp-alert/SET_MESSAGE', {})
        })
        .catch(error => {
            swofImageLoading.value = false
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

     const downloadSWOF = async (swof) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        exportLoading.value = true
        store
        .dispatch('app-project/downloadSWOF', swof)
        .then(response => {
            const { data } = response
            exportLoading.value = false
            const disposition = response.headers['content-disposition'];
            const filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            
            let fileURL = window.URL.createObjectURL(new Blob([data]));
            let fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
        
            fileLink.click();

        })
        .catch(error => {
            // exportLoading.value = false
            // store.commit('app/LOADING', false)
            console.log(error.response)
            store.commit('comp-alert/SET_MESSAGE', error.response.statusText)
        })
    }


    return {
        billingStatusOptions,
        billingStatusColor,
        officeLoading,
        officeOptions,
        officeSearch,
        categoryLoading,
        categoryOptions,
        categorySearch,
        busInitLoading,
        busInitOptions,
        busInitSearch,
        countryLoading,
        countryOptions,
        countrySearch,
        moduleLoading,
        moduleOptions,
        moduleSearch,
        projTypeLoading,
        projTypeOptions,
        projTypeSearch,
        researchLocLoading,
        researchLocOptions,
        researchLocSearch,
        researchMetLoading,
        researchMetOptions,
        researchMetSearch,
        servTypeLoading,
        servTypeOptions,
        servTypeSearch,
        billingRuleLoading,
        billingRuleOptions,
        billingRuleSearch,
        currencyLoading,
        currencyOptions,
        currencySearch,
        clientLoading,
        clientOptions,
        clientSearch,
        userLoading,
        userOptions,
        userSearch,
        shelfTypeLoading,
        shelfTypeOptions,
        shelfTypeSearch,
        studioRateCardLoading,
        studioRateCardOptions,
        studioRateCardSearch,
        qualSampleLoading,
        qualSampleOptions,
        qualSampleSearch,
        deptLoading,
        deptOptions,
        deptSearch,
        groupLoading,
        groupOptions,
        groupSearch,
        statusLoading,
        statusOptions,
        statusSearch,
        fieldLocationLoading,
        fieldLocationOptions,
        fieldLocationSearch,
        workTypesLoading,
        workTypesOptions,
        workTypesSearch,
        intercompanies,
        intercompaniesLoading,
        exportLoading,
        questionnaireLoading,
        questionnaire,
        swof,
        swofLoading,
        swofImage,
        swofImageLoading,
        swofNotificationLoading,
        sendSWOFNotification,
        swofQuestionList,
        downloadSWOF,

        fetchOffices,
        fetchCategories,
        fetchBusinessInit,
        fetchCountries,
        fetchModules,
        fetchProjectTypes,
        fetchResearchLocations,
        fetchResearchMethods,
        fetchServiceTypes,
        fetchBillingRules,
        fetchCurrencies,
        fetchUsers,
        fetchClients,
        fetchShelfTypes,
        fetchStudioRateCards,
        fetchQualSampleType,
        fetchGroups,
        fetchDepartments,
        fetchStatuses,
        fetchFieldLocations,
        downloadTimeline,
        fetchQuestionnaire,

        fetchSWOF,
        addSWOF,
        updateSWOF,
        updateSWOFURL,
        updateSWOFImage,
        deleteSWOFImage,
        addSWOFImage,
        addFavoriteProject,
        deleteFavoriteProject,
        // fetchIntercoWorkTypes,
        // fetchIntercos,
        // addInterco,
        // updateInterco,
        // deleteInterco,
    }

}